import { fork, takeEvery,takeLatest , select} from 'redux-saga/effects';
import ReactGA from "react-ga4";
import * as types from '../constants/ActionTypes'
import { fetchBotDetails, fetchLatLon, sendRMRequest } from '../api/index';
import { matchOffsetTime } from '../utils/timezone';
import { searchandDelay } from "../utils/parser";
import { 
  enableAppointmentForm,
  showIntentForm,
  addMessage,
  messageWillbeReceived,
  waitForForm
} from '../actions';
import { resume } from "../actions/ApiCalls";

import { intentList, IntentMessage } from '../constants/intents';
import queryString from 'query-string'

let getState = null;
let botProp = null;
let messageProp = null;


const rootSaga = function* rootSaga(params, dispatch) {
  yield fork(handleNewMessage, params);
  yield fork(handleLocations );
  yield fork(handleLiveChatHours );
  yield fork (handleIntentSubmission, params);
  yield fork ( handleRemoveMessages );
  yield fork(handleBotCallsuccess, params);
}

function matchIntent(input, dispatch, botProp, state){
  let ret = false;
  const session = localStorage.getItem('key');
  if (botProp.data.isGptAgent && !botProp.data.allow_functions) {
    const inputWord = input.toLowerCase();
    console.log("intentList is", intentList);
    intentList.forEach( (item) => {
      const regex = new RegExp(`\\b${item.toLowerCase()}\\b`, 'i');
      switch(item) {
        default:
          //if (regex.test(inputWord)) {
          if (item.toLowerCase() == inputWord.toLowerCase()) {
            //show message first
            dispatch(waitForForm());
            setTimeout( () => {
              dispatch(addMessage(IntentMessage[item], 'bot', session, true));
              dispatch(waitForForm())
            }, 1500);
            setTimeout( () => {
              dispatch(enableAppointmentForm(item, IntentMessage[item]));
              dispatch(showIntentForm());
            }, 6500);
            //setTimeout( () => {
              //dispatch(showChatAddMessage());
            //}, 3000);
            ret = true;
            console.log("item is", item.toLowerCase());
            console.log("inputWord is", inputWord.toLowerCase())
          }
        break;
      }
    });

    if (ret ===false) {
      //state.bot.data.ga4Tag
      const q_intents = state.bot.data.qrb_intents
      q_intents.forEach( (item) => {
        const regex = new RegExp(`\\b${item.var_label.toLowerCase()}\\b`, 'i'); 
        //if (regex.test(inputWord)) {
        if (item.var_label.toLowerCase() == inputWord.toLowerCase()) {
          //show message first
          dispatch(waitForForm());
          setTimeout( () => {
            //dispatch(addMessage(item.var_val, 'bot', session, true));
            const data = {
              message: item.var_val,
              author: 'bot',
              feedBack: true,
              disableSearchWebsiteText: false
            };
            searchandDelay(data,dispatch);
            dispatch(waitForForm())
          }, 1500);
          setTimeout( () => {
            dispatch(enableAppointmentForm(item.var_label, item.var_val));
            dispatch(showIntentForm());
          }, 6500);
          ret = true;
        }
      });
    }
  }
  return ret;
};
/* setTimeout(()=>{
      console.log('calling ga4 event');
      ReactGA.event('chat_open',{
        'item_category': 'init'
      });
    }, 8000); */
export const handleNewMessage = function* handleNewMessage(params) {
   yield takeEvery(types.ADD_MESSAGE, (action) => {
    params.dispatch(resume());
    const agent_id = localStorage.getItem("agent_id");
    const {bot_id} = queryString.parse(window.location.search);
    const state = params.store.getState();
    action.agent_id = agent_id;
    action.bot_id = bot_id;
    if ((state.message.chats.length == 2 || state.message.chats.length == 1)&& state.bot.data.ga4Tag !="") {
      if(state.bot.data.ga4Tag != null) {
        console.log('calling ga4 event');
        ReactGA.event('chat_start',{
          'item_category': 'start',
          'nonInteraction': true
        });
      }
    }
    const storedState = localStorage.getItem(`reduxState_${bot_id}`);
    if (storedState !== null) {
      const { message } = JSON.parse(storedState);
      if (message.chats.length > 0) {
        const { parameters, current_page, contexts } = message;
        action.parameters = parameters;
        action.current_page = current_page;
        action.contexts = contexts;
      }
    };
    console.log('action at saga handle new message is ', action)
    console.log('action at saga handle new message is ', state.bot?.data?.hideIntentForm)
    if (undefined !== state.bot?.data?.hideIntentForm && state.bot?.data?.hideIntentForm === false){
      let matched =  matchIntent(action.message, params.dispatch, botProp, state);
      console.log("the matched va now is ",matched);
      if (state.bot?.data?.allow_functions)
        matched =  false;
    
      if ( !matched ) {
        if (action.feedback)  params.dispatch(messageWillbeReceived());
        //setTimeout(function(){
          if (action.message != "NeverMind" && action.author =='Me') 
            params.socket.send(JSON.stringify(action))
        //}, 2500);
      }
    } else {
      if (action.feedback)  params.dispatch(messageWillbeReceived());
        if (action.message != "NeverMind" && action.author =='Me') 
          params.socket.send(JSON.stringify(action))
    }
  })
  yield takeLatest("API_CALL_REQUEST", dispatchToServiceHandler);
}

export const handleIntentSubmission = function* handleIntentSubmission(params) {
  
  yield takeEvery('PARAMETERS_POST_REQUEST', (action) => {
    const agent_id = localStorage.getItem("agent_id");
    const {bot_id} = queryString.parse(window.location.search) ;
    const session = localStorage.getItem('key');
    const dataToSend = {};
    dataToSend.parameters= action.formData;
    dataToSend.agent_id = agent_id;
    dataToSend.bot_id = bot_id;
    dataToSend.type = 'INTENTDATA';
    dataToSend.session = session
    params.socket.send(JSON.stringify(dataToSend))
  })
  //yield takeLatest("API_CALL_REQUEST", dispatchToServiceHandler);
}


const dispatchToServiceHandler=function* dispatchToServiceHandler (action){
  switch(action.subType){
    case 'BOT_CALL_REQUEST':
      yield fetchBotDetails(action);
      getState = (state) => state;
      const state =  yield select(getState);
      botProp = state.bot;
      messageProp = state.message;
      break;
        
    default:
      break;
  }
  switch (action.type) {
    case 'ENABLELOCS':
      yield fetchLatLon(action);
      break;
    
    case 'ENABLELCHOURS':
      yield matchOffsetTime(action);
      break;
    case 'REMOVE_MESSAGES':
      const {bot_id} = queryString.parse(window.location.search) 
      yield sendRMRequest(bot_id);
      break;

    default:
      break;
  }
}

export const handleLocations = function* handleLocations(params) {
  yield takeEvery("ENABLELOCS", dispatchToServiceHandler);
}
export const handleLiveChatHours = function* handleLiveChatHours(params) {
  yield takeEvery("ENABLELCHOURS", dispatchToServiceHandler);
}

export const handleRemoveMessages = function* handleRemoveMessages(params) {
  yield takeEvery("REMOVE_MESSAGES", dispatchToServiceHandler);
} 

export const handleBotCallsuccess = function* handleBotCallsuccess(params) {
  yield takeEvery('API_CALL_SUCCESS', (action) => {
    console.log('BOT_CALL_SUCCESS');
    if (action.subType == "BOT_CALL_SUCCESS") {
      const agent_id = localStorage.getItem("agent_id");
      const {bot_id} = queryString.parse(window.location.search) ;
      const session = localStorage.getItem('key');
      if (bot_id == 2026) {
        const action = {
          type: 'welcome_stream',
          message: `Act like a live chat agent for Indo Windows and use the following welcome message and wait for a response from the user.
      
            Welcome to Indo Window!::next-1000::I’m Lisa, your inbound sales rep. I’m here to answer your questions.::next-2000:: Ask a question or click below to learn more.
            below buttons must be included in every response.
            <button type="button" class="quick_reply">How do Indo Window Inserts Improve Energy Efficiency?</button>
            
            <button type="button" class="quick_reply">Can Indo Window Inserts Reduce Noise?</button>
            <button type="button" class="quick_reply">Are Indo Window Inserts Effective for Light and UV Control?</button>
            
            Limit your response to 50 words or less and add the following buttons after each response'.
            <button type="button" class="quick_reply">Get Pricing</button>
            <button type="button" class="quick_reply">Connect with Staff</button>
            
            If the user enters ‘Get Pricing’ or ‘Connect with Staff’, ask each of the following questions separately and wait for a response. Name, phone number and valid email are required. Please check for valid email address format.
            
            What is your name?
            How did you first learn about Indow window inserts?
            What type and size are your current windows?
            What is your budget range for window improvements?
            When do you plan to install window inserts?
            What is your phone number?
            Finally, we can follow up by email. What is your email address?
            
            After you collect contact information, add the following button.
            <button type="button" class"quick_reply">Disconnect</button>
            
            “Sounds good. Let me connect you with the right person at Indo Windows.::next-2000::To get started, what is your name?”`,
          bot_id,
          session,
        };
        params.socket.send(JSON.stringify(action));
      }
    }
  })
}
//

export default rootSaga;