const primaryBubbles  = (data) => {
    let priStyle = {
        color: '#787878',
        backgroundColor: '#dde3eb',
        borderColor: '#dde3eb',
    };
    if (undefined !== data.color && data.color !== '') priStyle.color = `#${data.color}`;
    if (undefined !== data.bg && data.bg !== '' )priStyle.backgroundColor = `#${data.bg}`;
    if (undefined !== data.bg && data.bg !== '' )priStyle.borderColor = `#${data.bg}`;
    
    return priStyle;
};

const secBubbles  = (data) => {
    let secStyle = {
        color: '#787878',
        backgroundColor: '#dde3eb',
        borderColor: '#dde3eb',
    };
    if (undefined !== data.secColor && data.secColor !== '') secStyle.color = `#${data.secColor}`;
    if (undefined !== data.secBg && data.secBg !== '' ) secStyle.backgroundColor = `#${data.secBg}`;
    if (undefined !== data.secBg && data.secBg !== '' ) secStyle.borderColor = `#${data.secBg}`;
    
    return secStyle;
};

const customCssData = (bot,paramOption, urlData) => {
    const customizeData = {
    bg: urlData.searchParams.get("bg"),
    color: urlData.searchParams.get("clr"),
    secBg: urlData.searchParams.get("secBg"),
    secColor: urlData.searchParams.get("secColor"),
    };
    if (bot.data !== null){
        if (
            bot.data.webchat_widget !== null 
            && undefined !== bot.data.webchat_widget.primary_color
            && paramOption === "2"
        ) customizeData.bg = bot.data.webchat_widget.primary_color.substring(1);

        if (
            bot.data.webchat_widget !== null 
            && undefined !== bot.data.webchat_widget.primary_text_color
            && paramOption === "2"
        ) customizeData.color = bot.data.webchat_widget.primary_text_color.substring(1);

        if (
            bot.data.webchat_widget !== null 
            && undefined !== bot.data.webchat_widget.secondary_color
            && paramOption === "2"
        ) customizeData.secBg = bot.data.webchat_widget.secondary_color.substring(1);

        if (
            bot.data.webchat_widget !== null 
            && undefined !== bot.data.webchat_widget.secondary_text_color
            && paramOption === "2"
        ) customizeData.secColor = bot.data.webchat_widget.secondary_text_color.substring(1);
    }
    return customizeData;
}

export {primaryBubbles, secBubbles, customCssData};