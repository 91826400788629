import { connect } from 'react-redux';
import AppointmentFormComponent from '../components/AppointmentForm';
import { 
  addFormData,
  changeQuickReplyMessage,
  continueAction,
  addMessage,
  showLoading,
  showFormError,
} from '../actions';
import chatValidationErrors from '../validators/chatValidationErrors';
import chatIsValid from '../validators/chatIsValid'; 

const mapDispatchToProps = dispatch => ({
  dispatch: (formData, type) => {
    //console.log("the feed back is"+feedback 
    ///dispatch(addFormData(formData));
    if (type === 'changeQrMessage') {
      dispatch(changeQuickReplyMessage(formData.item, formData.qrMessage));
    } else if(type === 'NeverMind' || type === 'chatAdMessage') {
      dispatch(continueAction());
      const session = localStorage.getItem('key');
      let message = formData.message;
      console.log(formData.nevermind)
      if (type === 'NeverMind')message = formData.nevermind;
      dispatch(addMessage('NeverMind', 'Me', session, true));
      setTimeout(()=>{
        dispatch(addMessage(message, 'Bot', session, false));
      }, 1000);
    } else if (type === 'submit_form') {
      dispatch(addFormData(formData));
      dispatch(showLoading());
    } else if (type === 'error_form') {
      console.log(formData)
      dispatch(showFormError());
    }
  },
});


export const AppointmentForm = connect(state => ({
  message: state.message,
  bot:state.bot,
  intentParameters: state.intentParameters
}), mapDispatchToProps)(AppointmentFormComponent)