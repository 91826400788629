import React,{ useState } from 'react';
import PropTypes from 'prop-types';

const IntentDataForm = (props) => {
  let deafultLocation = '';
  props.bot.data.locations.map((location, index) => { 
    if (index === 0) deafultLocation = location.name;
  });
  return(
    <div className="form-wrap"> 
      <fieldset className="form-columns-1 row">
          <div className="col-lg-12 col-md-12 col-sm-12">
              <label  className="label-text" placeholder="Enter your First name" htmlFor="firstname">
                  Name
              </label>
              <div className="input">
                  <input onChange= {props.changeHandler}  className="sg-input sg-form-field form-control" type="text" name="name" placeholder="Enter your name" required />
              </div>
          </div>
      </fieldset>
      <fieldset className="form-columns-1 row">
          <div className="col-12">
              <label  className="label-text" placeholder="Enter your email" htmlFor="firstname">
                  Email
              </label>
              <div className="input">
                  <input onChange= {props.changeHandler}  className="sg-input sg-form-field form-control" type="email" name="email" placeholder="Enter your email address" required />
              </div>
          </div>
      </fieldset>
      <fieldset className="form-columns-1 row">
          <div className="col-12">
              <label  className="label-text" placeholder="Enter your First name" htmlFor="firstname">
                  Phone
              </label>
              <div className="input">
                  <input onChange= {props.changeHandler}  className="sg-input sg-form-field form-control" type="tel" name="phone" placeholder="Enter your phone number" required minLength={10}/>
              </div>
          </div>
      </fieldset>
      <fieldset className="form-columns-1 row">
          <div className="col-12">
              <label  className="label-text" placeholder="message" htmlFor="message">
                Additional Infomation
              </label>
              <div className="input">
              <textarea onChange= {props.changeHandler} className="sg-form-field" name="comment" form="usrform"></textarea>
              </div>
          </div>
      </fieldset>
      {
        (props.bot.data.locations.length > 0)? 
        <fieldset className="form-columns-1 row">
          <div className="col-12 mb-3 select-item-1">
            <label className="label-text" placeholder="Preferred Location" htmlFor="Preferred Location">
              Preferred Location
            </label>
            <div className="input">
            <select  defaultValue={deafultLocation} name='location' onChange= {props.changeHandler} className="form-select db-select" aria-label="Default select example" tabIndex="0">
              <option value="">Select Preferred Location</option>
              { props.bot.data.locations.map((location, index) => {
                return <option value={location.name}>{location.name}</option>
              })}
            </select>
            </div>
          </div>
        </fieldset>
        :
        ""
      }
      <div className="sg-submit row">
          <div className="actions col-12 text-end">
              <input style={props.priStyles} type="submit" value="Submit" className="sg-button btn-primary"/>
          </div>
      </div>
      </div>
  )
}

IntentDataForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      message: PropTypes.string,
      author: PropTypes.string,
    })
  ),
  bot:PropTypes.object
}

export default IntentDataForm;