import React,{ useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import QuickReply from './quickReply';
import SmartChatMessage from './smartChatMessage';
import IntentDataForm from './intentDataForm';
import ChatAdMessage from './chatAdMessage';
import './../assets/css/main.css';
import { primaryBubbles, secBubbles, customCssData } from "../utils/customBubbles";
import { IntentMessage } from '../constants/intents';
import { 
  changeQuickReplyMessage,
} from '../actions'

let renderedWithStore = false;
let formELem = null;
const componentDidMount = () => {
  scrollToContent.bind(this);
  scrollToContent();
}

const scrollToContent = () => {
  //formELem.scrollIntoView();
  setTimeout(() => {
    if (undefined !== document.getElementsByClassName('form-wrap')) {
      const offsetTop = document.getElementsByClassName('form-wrap')[0].offsetTop;
      window.scrollTo(0,offsetTop -300);
    }
  }, 500);
  //formELem.parentNode.scrollTop = formELem.offsetTop;
}

const AppointmentForm = (props) => {
  useEffect(() => {
    // code to execute after component has mounted
    componentDidMount();
  }, []);
  const messageStoreObj = props.message;
  const botStoreObj = props.bot;
  const dispatchObj = props.dispatch;
  const [formData, setFormData] = useState({
    connect_type: '',
    name: '',
    comment: '',
    email: '',
    phone: '',
    location: '',
    error: false,
    errorText: [],
  });
  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    const targetName = event.target.name;
    if (targetName === 'connect_type') {
      const item = event.target.value;
      const qrMessage = IntentMessage[item];
      const data = { item , qrMessage}
      props.dispatch(data, 'changeQrMessage');
    }
  };
  const handleSubmit = (event) => {
    formData.intentAction = messageStoreObj.intentAction;
    formData.errorText = validateFields(formData);
    const target = event.target;
    const loc = target['location'];
    formData.location = loc.value;
    // console.log('data in form ', formData);
    if (formData.errorText == '') {
      props.dispatch(formData, 'submit_form');
    } else {
      formData.error = true;
      props.dispatch(formData, 'error_form');
    }
    event.preventDefault();
  };

  const validateFields = (data) => {
    let errorText = [];
    const validEmail = data.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    if (validEmail === null) errorText.push('Please provide valid email') ;
    //const validPhone = data.phone.match(/^\d{10,12}$/);
    //if (validPhone === null ) errorText.push('Please provide valid phone number');
    const validPhone = /^\+?\d+(?:[ ]?\d+)*$/.test(data.phone);
    if (!validPhone) errorText.push('Please provide valid phone number');
    return errorText;
  };

  const handleNeverMindBtn = () => {
    const data = {};
    data.nevermind = props.bot?.data?.smart_chat_never_mind;
    props.dispatch(data, 'NeverMind');
  }
  const author = props.message.author;
  let avatar = props.bot.data.webchat_settings.bot_avatar;
  if(author === "Me"){
    avatar = props.bot.data.webchat_settings.human_avatar;
  }
  if(!isNaN(author)){
    //avatar = props.imgSrc
    let operator = props.operators.data.find(function(element) {
        return element.id == author;
    });
    avatar = operator.profile_img_url;
  }
  
  const selectedOption = messageStoreObj.intentAction;
  const urlData = new URL(document.location);
  const paramOption = urlData.searchParams.get("option");
  const customizeData = customCssData(props.bot,paramOption, urlData);


  const priStyles = primaryBubbles(customizeData);
  const secStyles = secBubbles(customizeData);
  let customStyles = {};
  if (paramOption === "2")
    customStyles = priStyles;
return (
    <section className="main-dash-section site-glue-form-section" ref={(el) => { formELem = el; }}>
    <div className="container-fluid px-0 h-100">
        <div className="row flex-nowrap mx-0 g-0 h-100">
            <main className="col px-0">
                <div className="main-section human-hand-form-section" >
                    <div className="wrapper">
                    <div className="content row justify-content-center">
                        <div className="col-md-12 col-sm-12 col-lg-12 text-box">     
                             <div className="form-box" id="sign-up" name="sign-up">
                               <form onSubmit={ handleSubmit }>
                               <input type="hidden" name="type" value={selectedOption} />
                               {/* <QuickReply dispatch = {dispatchObj} message = {messageStoreObj} bot = {botStoreObj}  changeHandler = { handleInputChange } /> */}
                               {/* <SmartChatMessage dispatch = {dispatchObj} message = {messageStoreObj} bot = {botStoreObj} avatar = {avatar } changeHandler = { handleInputChange }/> */}
                               {
                                (props.message.showIntentForm) ?
                                <IntentDataForm 
                                  dispatch = {dispatchObj} 
                                  message = {messageStoreObj} 
                                  bot = {botStoreObj} 
                                  changeHandler = { handleInputChange }
                                  priStyles= { priStyles }
                                  secStyles = { secStyles }  
                                />
                                :
                                ""
                               }
                               </form>
                               {
                                ( props.message.showFormError) ?
                                <div className="bottom-text alert alert-danger alert-dismissible fade show">
                                  <ul>
                                    { formData.errorText.map((info, index) => (
                                      <li>{info}</li>
                                    ))}
                                  </ul>
                                </div>
                                :
                                ""
                               }
                               {
                                (props.message.showFeedbackText) ?
                                <div className="bottom-text alert alert-info alert-dismissible fade show">
                                  Please wait ...
                                </div>
                                :
                                ""
                               }
                               <div className="bottom-text">
                                <button style={secStyles} onClick={handleNeverMindBtn} className="btn btn-default">Never Mind</button>
                               </div>
                               {/* {
                                (props.message.showChatAddMessage) ?
                                  <ChatAdMessage dispatch = {dispatchObj} message = {messageStoreObj} bot = {botStoreObj} avatar = {avatar }/>
                                :
                                ""
                               } */}
                               </div>
                         </div>
                    </div>
                    </div>
                </div>
            </main>
        </div>
    </div>
</section>

  );
}

AppointmentForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      message: PropTypes.string,
      author: PropTypes.string,
    })
  ),
  bot:PropTypes.object
}

export default AppointmentForm