import { combineReducers } from 'redux'
import message from './message'
import bot from './bot'
import operators from './operators'
import intentParameters from './intentParameters';
const chat = combineReducers({
  message,
  bot,
  operators,
  intentParameters
})

export default chat