import {formatAMPM} from "./formatDate";
import { 
  messageReceived,
  messageWillbeReceived,
  disableUserInput,
  enableInput,
  enableCal,
  disableCal,
  enableLocs,
  enableLiveChatHours,
  hideSearchWebsiteMessage,
  disableSearchWebsiteMessage,
  enableSearchWebsiteMessage,
  continueActionNoLoading,
  additionalMessageRecieved
} from '../actions';
let finalMs = 0;
let completedMs = 0;
let loadingCounter = 1;
export const parseMessage = (message, showAncButton = false) => {
  console.log("showAncButton ", showAncButton);
  message = message.replaceAll('""', '"');
  let cmessage = message.toLowerCase();
  let n = cmessage.indexOf('<button');
  let l = cmessage.lastIndexOf('</');
  l = l  + 2;
  let c =  cmessage.indexOf('<cancel');
  let cEnd =  cmessage.lastIndexOf('</cancel>');
  let extract = message.substr(n, l);
  let cancelMsg = message.substr(c, cEnd);
  const urlRegex = /(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/gi;
  /*if (null != extract.match(urlRegex)) {
    let end = message.lastIndexOf('</a>');
    let text = message.substring(end+4,message.length );
    // console.log(end)
    console.log("At line 36 the extrat is",text)
    let start = text.indexOf('<');
    extract = text.substr(start, l);
    n = message.indexOf(extract);
  }*/
  let orignMessage = message;
  let finalMessage = '';
  let appendHTML = '';
  let disableInput = false;
  let enableCal = false;
  let showLocations = false;
  let showChatHours = false;
  let showCancelLink = false
  let dtFound = message.match(/::datepicker::/g);
  const locationsFound = message.match(/::locations::/g);
  const liveChatHours = message.match(/::livechathours::/g);
  if (dtFound) {
    enableCal = true;
  }
  if (locationsFound) {
    //console.log('locations faound');
    showLocations = true;
  }
  if (liveChatHours) {
    //console.log('liveChatHours faound');
    showChatHours = true;
  }
  //if (/<[a-z][\s\S]*>/i.test(extract)) {
  console.log('exract At 64 is ', extract);
  if (/<button[\s\S]*>/i.test(extract)) {  
    let etData = `  ${extract} 
                    `;
    let dInputIndex = etData.indexOf('%disable');
    if (dInputIndex > 0) {
      disableInput = true;
      //console.log('disable index is' + dInputIndex+etData)
      appendHTML = etData.substr(0,dInputIndex);
    }
    else
    	appendHTML = etData;
    let str1 = message.substr(0, n);
    let str2 = message.substr(l + 1);
    str2 = str2.replace('utton>','');
    str2 = str2.replace('>','');
    console.log('at 78 ', str1);
    console.log('at 79 ', str2)
    dInputIndex = str2.indexOf('%disable');
    str2 = str2.substr(0,dInputIndex);
    //if (dInputIndex > 0)  disableInput = true;
    //console.log('disable index is' + dInputIndex)
    finalMessage = str1 + str2;
    if (/<cancel[\s\S]*>/i.test(cancelMsg)) {
      cEnd = cEnd + 8;
      if (n > 0) str1 = cmessage.substr(0, n);
      else str1 = cmessage.substr(0, c);
      finalMessage = str1;
      showCancelLink = true;
      orignMessage = str1
    }
  }else{
    if (/<cancel[\s\S]*>/i.test(cancelMsg)) {
      cEnd = cEnd + 8;
      let str1 = message.substr(0, c);
      if (n > 0) str1 = message.substr(0, n);
      finalMessage = str1;
      showCancelLink = true;
      orignMessage = str1
    }
    let dInputIndex = message.indexOf('%disable');
    if(dInputIndex > 0){
      disableInput = true;
      let str1 = message.substr(0, dInputIndex);
      let str2 = message.substr(dInputIndex + 8);
      orignMessage = str1 + str2;
    }
  }
  finalMessage = finalMessage.replace('<cancel class"cancel_link" href="javascript:;">Cancel</cancel>',"");
  orignMessage = orignMessage.replace('<cancel class"cancel_link" href="javascript:;">Cancel</cancel>',"");
  appendHTML = appendHTML.replace('<cancel class"cancel_link" href="javascript:;">Cancel</cancel>', "");
  console.log('showCancelLink', showCancelLink);
  console.log('cancelMsg', cmessage)
  return { text: finalMessage, html: appendHTML, disableInput, orignMessage, enableCal, showLocations, showChatHours,showCancelLink };
};

export const createMarkup = (html, styles = '') => {
  if (styles !== '') {
    html = html.replace(/type="button"/g, 'type="button" style="color:'+styles.color+'; background-color: '+styles.backgroundColor+'"')
    html = html.replace(/class"quick_reply"/g, '');
    html = html.replace(/<a/g, '<a style="color:'+styles.color+'; background-color: '+styles.backgroundColor+'"');
  }
  return { __html: html };
};

export const createAnchor = (message) => {
  let c =  message.indexOf('<a');
  let cEnd =  message.lastIndexOf('</a>');
  return message.substr(c, cEnd);
}

export const removeColons = (message) => {
  const matches = message.match(/::(.+?)0::/g);
  let start = 0;
  let end = 0;
  let extract = 0;
  let res = '';
  const returnArr = [];
  if (matches !== null) {
    for (let i = 0; i < matches.length; i += 1) {
      //console.log(matches[i]);
      end = message.indexOf(matches[i]);
      if (res !== '') {
        if (`::next-${res}::` === matches[i]) {
          start = message.indexOf(`::next-${res}::`, end + 13);
        } else {
          start = message.indexOf(`::next-${res}::`);
        }
      }
      //console.log(start);
      extract = message.substring(start, end);
      extract = extract.replace(`::next-${res}::`, '');
      if (res === '') {
        returnArr.push(extract);
        // dispatch(messageReceived(extract,author))
      } else if (extract !== '') {
        returnArr.push(extract); // delay(res,extract,author,dispatch)
      }
      res = matches[i].replace(/\D/g, '');
    }
    // for left over message
    if (start <= end) start = end;
    end = message.length;
    extract = message.substring(start, end);
    extract = extract.replace(`::next-${res}::`, '');
    if (extract !== '') returnArr.push(extract);// delay(res,extract,author,dispatch)
  } else {
    returnArr.push(extract);// dispatch(messageReceived(data.message, data.author))
  }
  return returnArr;
};

export const searchandDelay=(data,dispatch, continueAct = false)=>{
  let cDate = new Date();
  let finalDate = formatAMPM(cDate)
  let message = data.message
  let author = data.author
  let feedBack = data.feedBack
  let matches = message.match(/::(.+?)0::/g);
  let start = 0
  var end = 0;
  var extract = 0
  var res = "";
  let lmore  = '';
  let showAncButton = false;
  console.log('data in snd delay in --- ', data)
  if (data.lmore) lmore  = data.lmore;
  if (data.showAncButton) showAncButton = data.showAncButton;
  finalMs = 0;
  completedMs = 0;
  loadingCounter = 1;
  if(null !== matches){
    for(let i = 0; i< matches.length; i++){
      end = message.indexOf(matches[i]);
      if(res!==""){
        if("::next-"+res+"::" === matches[i])
          start=message.indexOf("::next-"+res+"::",end+13);
        else
          start=message.indexOf("::next-"+res+"::");
      }
      extract = message.substring(start, end);
      extract = extract.replace("::next-"+res+"::","");
      if(res === ""){
        dispatch(messageReceived(extract,author,matches.length,finalDate,feedBack,lmore,showAncButton))
        if (data.disableSearchWebsiteText) dispatch (disableSearchWebsiteMessage())
        dispatch (hideSearchWebsiteMessage())
        if (!data.disableSearchWebsiteText) dispatch (enableSearchWebsiteMessage())
      }
      else{
        if(extract!=="")
          delay(res,extract,author,dispatch,matches.length,finalDate,feedBack,continueAct,lmore, showAncButton)
      }
      res = matches[i].replace(/\D/g, "");
    }
    //for left over message
    if(start <= end )
      start=end;
    end = message.length
    extract = message.substring(start, end);
    extract = extract.replace("::next-"+res+"::","");
    if(extract!=="")
      delay(res,extract,author,dispatch, matches.length,finalDate,feedBack,continueAct,lmore, showAncButton)
  }else{
    const message = parseMessage(data.message, showAncButton);
    let cmessage = data.message.replace("::datepicker::","");
    if (message.disableInput) dispatch(disableUserInput()) ;
    else dispatch(enableInput())
    if (message.enableCal) dispatch(enableCal()) ;
    else dispatch(disableCal())
    if (message.showLocations) {
      cmessage.replace("::locations::","");
      dispatch(enableLocs()) ;
      if (!continueAct) dispatch(messageWillbeReceived(true));
    }
    else {
      if (message.showChatHours) {
        cmessage.replace("::livechathours::","");
        dispatch(enableLiveChatHours()) ;
      } else {
        dispatch(messageReceived(cmessage, data.author,0,finalDate,feedBack,lmore, showAncButton));
        if (data.disableSearchWebsiteText) dispatch (disableSearchWebsiteMessage())
        dispatch (hideSearchWebsiteMessage())
        if (!data.disableSearchWebsiteText) dispatch (enableSearchWebsiteMessage())
      }
    }
  }
  if (continueAct) dispatch(continueActionNoLoading());
}

const delay=(ms,text,author,dispatch,extraChatsCount,finalDate,feedBack, continueAct = false, lmore = '', showAncButton = false)=>{
  finalMs=parseInt(ms)+parseInt(finalMs) 
  if(loadingCounter <= 1 && !continueAct)//if not showing loading status
    dispatch(messageWillbeReceived(feedBack));
  loadingCounter ++;
  setTimeout(function(){ 
    completedMs = parseInt(ms)+parseInt(completedMs) 
    //console.log(text)
    const message = parseMessage(text, showAncButton);
    //console.log('show chathours markup');
    //console.log(message);
    let cmessage = text.replace("::datepicker::","");
    //console.log(message)
    if (message.disableInput) dispatch(disableUserInput()) ;
    else dispatch(enableInput())
    if (message.enableCal) dispatch(enableCal()) ;
    else dispatch(disableCal())
    if (message.showLocations) {
      //console.log('show location markup');
      message.text.replace("::locations::","");
      dispatch(enableLocs()) ;
      if (!continueAct) dispatch(messageWillbeReceived(true));
    }
    else {
      if (message.showChatHours) {
        //console.log('show chathours markup');
        cmessage.replace("::livechathours::","");
        dispatch(enableLiveChatHours()) ;
        //dispatch(messageWillbeReceived(true));
      } else dispatch(additionalMessageRecieved(cmessage,extraChatsCount,finalDate,feedBack, lmore, showAncButton));
    } 
    if(completedMs !== finalMs && !continueAct)
      dispatch(messageWillbeReceived(feedBack));
  }, finalMs);
}