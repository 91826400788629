import React, { Component } from "react"
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

//import "./App.css"
import MessagesList from "./containers/MessagesList"
import { AddMessage } from "./containers/AddMessage"
import { AppointmentForm } from "./containers/AppointmentForm"
import { ErrorMessage } from "./containers/ErrorMessage"
import {ApplicationError} from "./errors/AppErrors"
import {initCall} from "./actions/ApiCalls"
import './App2.css';
import './App.css';
import './assets/css/animate.css';

class App extends Component {
  refreshPage() {
    window.location.reload(false);
  }
  render() {
    let optionClassName = 'chat-box';
    
    if(this.props.bot.cssTheme === 'option2') {
      optionClassName = 'chat-box chat-box-optional';
    }

    if(this.props.bot.error){
      return(<div  className={optionClassName}>
        <ErrorMessage />
      </div>)
    }
    else{
      if (this.props.bot.fetching) {
        return  (
          <div  className={optionClassName}>
            Please wait while we look up an agent.....
          </div>
        )
      }
      if (this.props.bot.lostConnection) {
        return  (
          <div  className={optionClassName}>
            Agent connection lost….Reconnect now
            <button onClick={this.refreshPage}>Click to reload!</button>
          </div>
        )
      }
      // if (this.props.message.showAppointmentForm) {
      //   return  (
      //     <div  className={optionClassName}>
      //      <AppointmentForm/>
      //     </div>
      //   )
      // }
      // if (this.props.message.showConnectSuportForm) {
      //   return  (
      //     <div  className={optionClassName}>
      //      <ConnectSupportForm/>
      //     </div>
      //   )
      // }
      return (
        <div  className={optionClassName}>
          <MessagesList />
          {
            (this.props.message.showAppointmentForm) ?
            <AppointmentForm/>
            :
            <AddMessage />
          }
        </div>
      )
    }
  }
}

App.propTypes = {
  bot:PropTypes.shape({
    fetching: PropTypes.bool,
    data: PropTypes.object,
    error: PropTypes.bool,
  })
}

const mapDispatchToProps = dispatch => ({
  dispatch: (subType,bot_id) => {
    setTimeout(function(){
      dispatch(initCall(subType,bot_id))
    },1000);
  }   
})

//export default App

export default App = connect(state => ({
  bot: state.bot,
  message: state.message,
}), mapDispatchToProps)(App)