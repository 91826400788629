export const attributeValidators = {  
  message: messageValid,
  //email: emailValid,
  //terraform_planets: terraformPlanetValid
}

function messageValid(action){
  return action.message.length > 0;
}

/*function emailValid(astronaut) {  

}*/

