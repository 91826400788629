export const intentList = [
  'Request an Appointment',
  'Request a Callback',
  'Insurance Benefits',
  'New Patient Question',
  'Live Chat Request',
  'Get a Quote',
  'Become a Member',
  'Connect with Support Team',
  'Connect with Sales Team',
  'Schedule a Demo',
  'Connect with Client Experience Rep',
  'Connect with Staff',
  'Chat with Staff',
  'Connect with Support',
  'Get an Estimate',
  'Retain Best Attorney',
  'Legal Question',
  'Make Arrangements',
  'New Request for Callback',
  'New Client Appointment Request',
];

export const IntentMessage = [];
IntentMessage['Request an Appointment'] = 'Sounds good. Enter the information below and I’ll send our staff your message along with a transcript of our conversation so they can contact you and schedule your appointment.';
IntentMessage['Request a Callback'] = 'Sounds good. Enter the information below and I’ll send our staff your message along with a transcript of our conversation so they can contact you ASAP.';
IntentMessage['Insurance Benefits'] = 'Sounds good. Enter the information below and I’ll send our staff your message along with a transcript of our conversation so they can contact you to verify your insurance benefits.';
IntentMessage['New Patient Question'] = 'Sounds good. Enter the information below and I’ll send our Client Experience Rep your message along with a transcript of our conversation so they can schedule your appointment';
IntentMessage['Live Chat Request'] = 'Sounds good. Enter the information below and I’ll send our Client Experience Rep your message along with a transcript of our conversation so they can schedule your appointment';
IntentMessage['Get a Quote'] = 'Sounds good. Enter the information below and I’ll send our staff your message along with a transcript of our conversation so they can contact you and put together pricing.';
IntentMessage['Become a Member'] = 'Sounds good. Enter the information below and I’ll send our staff your message along with a transcript of our conversation so they can contact you regarding membership.';
IntentMessage['Connect with Support Team'] = 'Sounds good. Enter the information below and I’ll send our support team your message along with a transcript of our conversation so they can follow up with your ASAP.';
IntentMessage['Connect with Sales Team'] = 'Sounds good. Enter the information below and I’ll send our sales team your message along with a transcript of our conversation so they can follow up with your ASAP.';
IntentMessage['Schedule a Demo'] = 'Sounds good. Enter the information below and I’ll send our sales team your message along with a transcript of our conversation so they can schedule your demo.';
IntentMessage['Connect with Client Experience Rep'] = 'Sounds good. Enter the information below and I’ll send our Client Experience Rep your message along with a transcript of our conversation so they can follow up ASAP.';
IntentMessage['Connect with Staff'] = 'Sounds good. Enter the information below and I’ll send our staff your message along with a transcript of our conversation so they can follow up ASAP.';
IntentMessage['Connect with Support'] = 'Sounds good. Enter the information below and I’ll send our support team your message along with a transcript of our conversation so they can follow up with your ASAP.';
IntentMessage['Get an Estimate'] = 'Sounds good. Enter the information below and I’ll send our sales team your message along with a transcript of our conversation so they can put together an estimate.';
IntentMessage['Chat with Staff'] = 'Sounds good. Enter the information below and I’ll check to see if our staff is available to chat live now.';
IntentMessage['Retain Best Attorney'] = 'Sure. Complete the form below we can set you up for a free case evaluation with a member of our legal team.';
IntentMessage['Legal Question'] = 'Sounds good. Complete the form below and we can set you up for an in-depth legal consultation with an experienced attorney for a $390 flat fee. You will get detailed answers to all of your legal questions. You will get a full credit for the $390 if you retain us for a case at that same meeting.';
IntentMessage['Make Arrangements'] = 'Sure. Complete the form below and our staff will contact you to discuss funeral arrangements.';
IntentMessage['New Request for Callback'] = 'Sure. Complete the form below and our staff will contact you.';
IntentMessage['New Client Appointment Request'] = 'Sure. Complete the form below and our staff will contact you.';
