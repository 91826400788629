import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import queryString from 'query-string';
//import { createLogger } from 'redux-logger'

import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker';
import reducers from './reducers'
import rootSaga from './sagas'
import setupSocket from './sockets';
import messageListner from './listners/iframeMessage';

//import formValidationMiddleware from './middlewares/validationMiddleware'

const sagaMiddleware = createSagaMiddleware()
//const loggerMiddleware = createLogger()
const dateItem = localStorage.getItem('timeStamp');
const {bot_id} = queryString.parse(window.location.search);
let compareDate = null ;
let currDate =  new Date();


let persistedState = localStorage.getItem(`reduxState_${bot_id}`) 
  ? JSON.parse(localStorage.getItem(`reduxState_${bot_id}`))
  : {};
const store = createStore(
    reducers,
    persistedState,
    applyMiddleware(
      //formValidationMiddleware,
      //loggerMiddleware,
      sagaMiddleware
    )
  );
store.subscribe(()=>{
  const storeState = store.getState();
  //storeState.bot.lostConnection = false;
  if (storeState.bot.data !== null){
    if (dateItem == null) {
      localStorage.setItem('timeStamp', Date.now());
    }
    if (dateItem != null) {
      let date2 = new Date(parseInt(dateItem));
      console.log(date2);
      compareDate = date2.getTime() + 1000 * 60 * 60 * 24;
    }
    if (
      storeState.bot.data.webchat_settings.chatbot_widget_persistence == "on"
      ||
      storeState.bot.data.webchat_widget.chatbot_widget_persistence2 == "on"
    ) {
    localStorage.setItem(`reduxState_${bot_id}`, JSON.stringify(storeState));
    if (dateItem != null) {
      if (currDate > compareDate) {
        localStorage.setItem('timeStamp', Date.now());
      }
    }
    } else {
      localStorage.removeItem(`reduxState_${bot_id}`);
      localStorage.removeItem(`timeStamp`);
    }
  }
});
let socket = setupSocket(store.dispatch, store);
messageListner(store.dispatch);
const dispatch = store.dispatch;
sagaMiddleware.run(rootSaga, { socket, dispatch, store })

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)
serviceWorker.unregister()
/*
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();*/
