import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { parseMessage, createMarkup, createAnchor } from '../utils/parser';
import ExtraMessage from './ExtraMessage';
import './message2.css';
import './message.css';

//import cssOPtion;

class Message extends Component { 
  
  render() {
    return message(this.props);
  }
}

const message = (props) => {
  let finalDate = '';
  let cName = 'even';
  let avatar = props.bot.data.webchat_settings.bot_avatar;
  const urlData = new URL(document.location);
  const paramOption = urlData.searchParams.get("option");
  let lmore = props.lmore;
  const showAncButton = props.message.showAncButton;
  let lmoreClass = 'learn-more-date';
  //console.log('lmore is ------------>', props.message);
  if (props.bot.data.webchat_widget !== null && undefined !== props.bot.data.webchat_widget.bot_avatar && paramOption === "2") 
    avatar = props.bot.data.webchat_widget.bot_avatar;
  let author = props.message.author;
  let message = props.message.message;
  if(author === "Me"){
    avatar = props.bot.data.webchat_settings.human_avatar
    if (
      props.bot.data.webchat_widget !== null 
      && undefined !== props.bot.data.webchat_widget.human_avatar
      && paramOption === "2"
    ) avatar = props.bot.data.webchat_widget.human_avatar;
    cName = "odd";
    lmore = '';
  }
  if (lmore != '')
    lmoreClass = 'learn-more-date';
  if(!isNaN(author)){
    //avatar = props.imgSrc
    let operator = props.operators.data.find(function(element) {
        return element.id == author;
    });
    //console.log(operator)
    avatar = operator.profile_img_url
  }
  //console.log(avatar+author)
  let customStyles =props.priStyles;
  if (cName === 'odd') {
    customStyles =props.secStyles;
  }
  if(undefined !== props.message.extraChats ){  
    return(
        <li className={cName}>
          <div className="chat-image"> 
            <img alt="male" src={avatar}/> 
          </div>
          <div className="chat-body">
              <div className="chat-text" style={customStyles}>
                  <p dangerouslySetInnerHTML={createMarkup(message,'')}/>              
              </div>
              {props.message.extraChats.map( (message, index) => (
                    //console.log(message)
                    <ExtraMessage key={message.id}
                     extraMessage={message}
                     origMessage = {props.origMessage}
                     dispatchAddMessage={props.dispatchAddMessage}
                     dispatchDisableInput = {props.dispatchDisableInput}
                     dispatchEnableInput = {props.dispatchEnableInput}
                     handleClick={props.handleClick}
                     extraChatsCount = {props.message.extraChatsCount}
                     currentIndex = {index}
                     dtime ={props.dtime}
                     bot = {props.bot}
                     style={customStyles}
                     lmore = {lmore }
                     showAncButton = { showAncButton }
                     />
                  ))}          
          </div>                                                              
        </li>
      )
  }
  else
    if(undefined !== message){
      if(props.message.extraChatsCount < 1)
        finalDate = props.dtime;
      let messages = {text:'',html:'',orignMessage:''};
      if (!showAncButton) messages = parseMessage(message, showAncButton);
      if (messages.disableInput) {
        //console.log('disable message');
      }
      if (showAncButton) {
        messages.html = createAnchor(message);
        //messages.text = messages.orignMessage;
        let aStrt =  message.indexOf('<a');
        if (aStrt === -1) aStrt = message.length;
        const text = message.substr(0, aStrt);
        messages.orignMessage =text;
        messages.text =text;
      }
      if(messages.html === "") {
        if(props.bot.cssTheme === 'option2') {
          //console.log(customStyles);
          return(
            <li className={cName}>
              <div className="chat-image"> 
                <img alt="male" src={avatar}/> 
              </div>
              <div className="chat-body">
                <div class="date-div">
                  <div className="chat-text " style={customStyles}>
                   <p dangerouslySetInnerHTML={createMarkup(messages.orignMessage,customStyles)}/>             
                  </div>
                  <div className={lmoreClass}>
                    {
                      (messages.showCancelLink) ?
                      <span><a onClick={props.handleClick}  href="javascript:;">Never Mind</a>&nbsp;</span>
                      :
                      ""
                    }
                    {
                    (lmore !== '') ?
                    <span dangerouslySetInnerHTML={createMarkup(lmore,'')}/>
                    :
                    ""
                    }
                    {finalDate}
                  </div>
                </div>
            </div>                                                              
            </li> 
          )
        } else {
          //console.log("date-div 4");
          return(
            <li className={cName}>
              <div className="chat-image"> 
                <img alt="male" src={avatar}/> 
              </div>
              <div className="chat-body">
                  <div className="chat-text date-div">
                     <p dangerouslySetInnerHTML={createMarkup(messages.orignMessage,customStyles)}/>
                     <div className={lmoreClass}>
                     {
                      (messages.showCancelLink) ?
                      <span><a onClick={props.handleClick}  href="javascript:;">Never Mind</a>&nbsp;</span>
                      :
                      ""
                    }
                      <span dangerouslySetInnerHTML={createMarkup(lmore,'')}/>
                      {finalDate}
                    </div>    
                  </div>
              </div>                                                              
            </li>
          )
        } 
      } else {
        if(props.bot.cssTheme === 'option2') {
          let btnMrkup = ``;
          if (messages.html.includes('type="qrb"') || messages.html.includes('class"quick_reply"')) {
            btnMrkup = createMarkup(messages.html,customStyles);// CTA style
          } else {
            const html = messages.html.replace(/type="button"/g, 'type="button" style="font-weight:100;"')
            btnMrkup = createMarkup(html);// without custom styling
          }
          return(
            <li className={cName}>
              <div className="chat-image"> 
                <img alt="male" src={avatar}/> 
              </div>
              <div className="chat-body">
                <div class="date-div">  
                  <div className="button-include"> 
                    <div className="chat-text " style={customStyles}>  
                      <p dangerouslySetInnerHTML={createMarkup(messages.text,customStyles)}/>        
                    </div>
                    <br/>
                    <div className={lmoreClass}>
                    {
                      (messages.showCancelLink) ?
                      <span><a onClick={props.handleClick}  href="javascript:;">Never Mind</a>&nbsp;</span>
                      :
                      ""
                      }
                      <span dangerouslySetInnerHTML={createMarkup(lmore,'')}/> 
                      {finalDate}
                    </div> 
                    <div onClick={props.handleClick} className="quick_reply" dangerouslySetInnerHTML={btnMrkup} />
                  </div>
                </div>
              </div>                                                              
            </li>
          )
        } else {
          return(
            <li className={cName}>
              <div className="chat-image"> 
                <img alt="male" src={avatar}/> 
              </div>
              <div className="chat-body">
                  <div className="button-include"> 
                    <div className="chat-text">  
                      <p dangerouslySetInnerHTML={createMarkup(messages.text,customStyles)}/>  <div className={lmoreClass}><span dangerouslySetInnerHTML={createMarkup(lmore,'')}/> {finalDate}</div>         
                    </div>
                    <div onClick={props.handleClick} className="quick_reply" dangerouslySetInnerHTML={createMarkup(messages.html)} />
                  </div>
              </div>                                                              
            </li>
          )
        }
      }
    }else
      return "";
}

Message.propTypes = {
  message: PropTypes.string,
  author: PropTypes.string,
  props:PropTypes.object,
  bot:PropTypes.shape({
    data: PropTypes.object,
    error: PropTypes.bool,
    fetching: PropTypes.bool
  }),
}

export default Message

//12 hours to add