import React from 'react'
import PropTypes from 'prop-types'

const ErrorMessage = (props) => {
  
  return (
    <div className="row send-chat-box">
        <strong>No agents available at this time.</strong>
    </div>
  )
}

ErrorMessage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      message: PropTypes.string,
      author: PropTypes.string,
    })
  )
}

export default ErrorMessage