const initialState = {
  fetching: false,
  formData: null,
  error: false
};

const intentParameters = (state = initialState, action) => {
  switch (action.subType) {
    case 'PARAMETERS_POST_REQUEST':
      return { ...state, fetching: true, formData: action.formData, error: false };
    case 'PARAMETERS_POST_SUCCESS':
      return { ...state, fetching: false, formData: null, error:false };
    case 'PARAMETERS_POST_FAILURE':
      return { ...state, fetching: false, formData: null, error: true };
    default:
      return state
  }
}

export default intentParameters;