import { restart_chat, clearMessage } from '../actions'
const messageListner = (dispatch) => {
  window.addEventListener('message', function(event) {
    console.log("Message received from the parent: " + event.data); // Message received from parent
    if (event.data == 'clear_chat')
      dispatch(restart_chat());
      dispatch(clearMessage());
  });
}

export default messageListner;