const initialState = {
    fetching: false,
    data: null,
    error: false
};

const operators = (state = initialState, action) => {
    switch (action.subType) {
        case 'OPERATORS_CALL_REQUEST':
            return { ...state, fetching: true, data: null, error: false };
        case 'OPERATORS_CALL_SUCCESS':
            return { ...state, fetching: false, data: action.data, error:false };
        case 'OPERATORS_CALL_FAILURE':
            return { ...state, fetching: false, data: null, error: true};
        default:
            return state
    }
  }
  
export default operators