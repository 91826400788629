const optionClicked  = (chats, option) => {
  const found = chats.findIndex((element) => {
    if(element.author !== 'Me') {
      //console.log(element);
      if(element.message.indexOf(option) >= 0) return true;
      if(undefined !== element.extraChats) {
        const indexFound = element.extraChats.findIndex((e) => {
          const index = e.message.indexOf(option);
          if (index >=0) return true;
        });
        if(indexFound >=0 ) return true;
      }
    }
  });
  return found;
};

export { optionClicked };