import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Message from './Message';
import './messagelist2.css';
import './messagelist.css';
import { primaryBubbles, secBubbles,customCssData } from "../utils/customBubbles";
import { optionClicked } from '../utils/searchChats';
/* const MessagesList = ({ messages }) => (
  //console.log(messages)
    <ul className="chat-list slimscroll p-t-30">
    {messages.map(message => (
        (undefined !== message.errors)?
        "": <Message
        key={message.id}
        {...message}
        />
    ))}
    </ul>
) */
let renderedWithStore = false;
class MessagesList extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.index = 0;
    this.scrollcounter = 0;
  }

  componentDidMount() {
    if (renderedWithStore) {
      const { dispatch } = this.props;
      if (!this.props.message.showFeedbackText)
        dispatch();
      this.scrollToContent = this.scrollToContent.bind(this);
    }
    renderedWithStore = true;
  }

  componentDidUpdate() {
    if (renderedWithStore) this.scrollToContent();
    // console.log("the index is"+this.index)
  }

  /*handleClick(e) {
    // console.log(e.target.nodeName)
    const { dispatchAddMessage } = this.props;
    if (e.target.nodeName === 'BUTTON') dispatchAddMessage(e.target.innerHTML, 'Me');
  }*/
  handleClick(e){
    //console.log(e);
    let { message } = this.props;
    let foundIndex = optionClicked(message.chats, e.target.textContent);
    let clicked = false;
    let showLoading = true;
    if (foundIndex >= 0) {
      //console.log('inside handle click');
      //console.log(foundIndex);
      //if (message.chats[foundIndex].clicked) clicked = true;
    }
    console.log("e.target.nodeName ", e.target.nodeName);
    if((e.target.nodeName === "BUTTON" || e.target.nodeName === "A") && !clicked){
      if(undefined !== e.target.attributes[1]){
        var locationPattern = /location_botid/g;
        var locMatch = e.target.attributes[1].name.match(locationPattern)
        if(undefined !== e.target.attributes[2] && undefined !== e.target.attributes[2].name)
          locMatch = e.target.attributes[2].name.match(locationPattern)
        if(undefined !== e.target.attributes[3] && undefined !== e.target.attributes[2].name)
          locMatch = e.target.attributes[3].name.match(locationPattern)
        //console.log(e.target.attributes[1].name);
        if(null !== locMatch){
          var numberPattern = /\d+/g;
          var m = e.target.attributes[1].name.match( numberPattern )
          if(undefined !== e.target.attributes[2] && undefined !== e.target.attributes[2].name)
            m = e.target.attributes[2].name.match( numberPattern )
          if(undefined !== e.target.attributes[3] && undefined !== e.target.attributes[3].name)
            m = e.target.attributes[3].name.match( numberPattern )
          var locId = parseInt(m);
          const urlSearchParams = new URLSearchParams(window.location.search);
          const params = Object.fromEntries(urlSearchParams.entries());
          let addedParams = `bot_id=${locId}`;
          if (undefined !== params.option) {
            Object.entries(params).forEach(([key, value]) => {
              if (key !== 'bot_id') addedParams += `&${key}=${value}`;
            });
          }
          window.location = "https://www.webchatbot.app/?"+addedParams;
        }else{
          var mulSelPattern = /mul_sel/g;
          //console.log(e.target.attributes);
          if (undefined !== e.target.attributes[2]) {
            var mulSelMatch = e.target.attributes[2].name.match(mulSelPattern);
            //console.log('handle clicked called');
            //console.log(e.target.textContent);
            //console.log(mulSelMatch);
            if (null !== mulSelMatch) {
              this.props.dispatchAddSelList(e.target.textContent);
            } else {
              //console.log('dispatchClickedMessage');
              this.props.dispatchClickedMessage();
              if (message.showIntentForm) showLoading = false;
              if (e.target.getAttribute('target') == null)
                this.props.dispatchAddMessage(e.target.textContent,"Me", showLoading);
            }
          } else {
            //console.log('dispatchClickedMessage');
            this.props.dispatchClickedMessage();
            if (message.showIntentForm) showLoading = false;
            if (e.target.getAttribute('target') == null)
              this.props.dispatchAddMessage(e.target.innerHTML,"Me", showLoading);
          }
          
        }
      }else{
        if (message.showIntentForm) showLoading = false;
        if (e.target.getAttribute('target') == null)
          this.props.dispatchAddMessage(e.target.innerHTML,"Me", showLoading);
      }
    }
  }
  async scrollToContent() {
    // if(this.index >=1 )
    // this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    const {bot,message } = this.props;
    if (bot.data.webchat_settings.open_chatbot_widget_50 === 'on' && message.chats.length === 1) 
      return true;
    else if (message.streaming === true){
      if (this.scrollcounter > 1) {
        await new Promise(resolve => setTimeout(resolve, 2500));
        this.messagesEnd.parentNode.scrollTop = this.messagesEnd.offsetTop;
      }
      this.scrollcounter++;
    }
    else
      this.messagesEnd.parentNode.scrollTop = this.messagesEnd.offsetTop;
  }

  render() {  
    const { message, bot, operators } = this.props;
    const urlData = new URL(document.location);
    //console.log("the url data is");
    //console.log(urlData);

    /**
     * const bg = botWidget.bot.primary_color.substring(1);
      const clr = botWidget.bot.primary_color_text.substring(1);
      const secBg = botWidget.bot.secondary_color.substring(1);
      const secColor = botWidget.bot.secondary_text_color.substring(1);
     */
    const paramOption = urlData.searchParams.get("option");
    const customizeData = customCssData(bot,paramOption, urlData);


    const priStyles = primaryBubbles(customizeData);
    const secStyles = secBubbles(customizeData);
    let autoHeight = 'chat-list slimscroll';
    if (message.showIntentForm) {
      autoHeight = 'chat-list slimscroll no-height';
    }
    if (message.loading) {
      this.index = message.chats.length - 1;
      let imgSrc = bot.data.webchat_settings.bot_avatar;
      if (
        bot.data.webchat_widget !== null 
        && undefined !== bot.data.webchat_widget.bot_avatar
        && paramOption === "2"
      ) imgSrc = bot.data.webchat_widget.bot_avatar;
      if (undefined !== message.chats[this.index - 1]) {
        const { author } = message.chats[this.index - 1];
        if (! isNaN(author)) {
          let operator = operators.data.find(function (element) {
            return element.id == author;
          });
          //console.log(operator);
          imgSrc = operator.profile_img_url;
        }
      }
      let customStyles = {};
      if (paramOption === "2")
        customStyles = priStyles;
      console.log("bot.loadedState ", bot);
      return (
        <ul className={autoHeight}>
           { message.chats.map((chat, index) => (
             (undefined !== chat.errors)?
             "": <Message 
                        key = {chat.id} 
                        message = { chat } 
                        origMessage = {this.props.message}
                        extraChats = { message.extraChats }
                        extraChatsCount = { chat.extraChatsCount }
                        dispatchAddMessage = { this.props.dispatchAddMessage }
                        dispatchDisableInput = { this.props.dispatchDisableInput }
                        dispatchEnableInput = { this.props.dispatchEnableInput }
                        handleClick = { this.handleClick }
                        bot = { bot }
                        operators = { operators }
                        dtime = { chat.dtime }
                        priStyles= { priStyles }
                        secStyles = { secStyles }
                        lmore = { chat.lmore }
                        showAncButton = { message.showAncButton }
                      />
            ))}

           { (!bot.loadedState) ?
              <li className="even">
                  <div className="chat-image"> 
                    {(undefined !== message.chats[this.index] && message.chats[this.index].extraChatsCount > 0)?"":<img alt="male" src={imgSrc}/> }
                  </div>
                  {
                    (bot.data.isCXagent && message.showCXText) || (bot.data.isGptAgent && message.showCXText)? 
                    <div class="chat-body">
                      <div class="chat-text loading-wait" style={customStyles}>
                      <p> One moment please... </p>
                      </div>
                    </div>
                    :
                    <div className="chat-body loading-bar">
                      <div className="chat-text" style={priStyles}>
                          <p className="loading-dots"> <h1 className="dot one">.</h1><h1 className="dot two">.</h1><h1 className="dot three">.</h1></p>
                      </div>
                    </div>
                  }
              </li>
            :
            ""
          }
           <div style={{ float:"left", clear: "both" }}
              ref={(el) => { this.messagesEnd = el; }}>
          </div>
        </ul>
      );
    } else {
      let imgSrc2  = ""
      if (message.chats.length > 0){
        this.index = message.chats.length -1;
        let imgSrc2  = bot.data.webchat_settings.bot_avatar
        if (bot.data.webchat_widget !== null && undefined !== bot.data.webchat_widget.bot_avatar && paramOption === "2") 
          imgSrc2 = bot.data.webchat_widget.bot_avatar;
        if (undefined !== message.chats[this.index]) {
          let msg = message.chats[this.index]
          if (!isNaN(msg.author)) {
            let operator = msg.author
            let oAvatar = operators.data.find(function(element) {
                            return element.id == operator;
                          });
            imgSrc2  = oAvatar.profile_img_url
            //console.log("test oavatar")
            //console.log(imgSrc2);
          }
        }
      }
      console.log(" message.showAncButton  message.showAncButton  message.showAncButton", message)
      return(
        <ul className={autoHeight}>
         { message.chats.map(chat => (
             (undefined !== chat.errors)?
             "": <Message 
             key = { chat.id } 
             message = { chat } 
             extraChats = { message.extraChats }
             origMessage = {this.props.message}
             dispatchAddMessage = { this.props.dispatchAddMessage }
             dispatchDisableInput = { this.props.dispatchDisableInput }
             dispatchEnableInput = { this.props.dispatchEnableInput }
             handleClick = { this.handleClick }
             bot = { bot }
             extraChatsCount = { chat.extraChatsCount }
             dtime = { chat.dtime }
             operators = { operators }
             priStyles= { priStyles }
             secStyles = { secStyles }
             lmore = { chat.lmore }
           />
         ))}
         <div style={{ float:"left", clear: "both" }}
              ref={(el) => { this.messagesEnd = el; }}>
          </div>
         </ul>
       )
    }
  }
}
MessagesList.propTypes = {
  message: PropTypes.shape({
    chats: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        message: PropTypes.string,
        extraChats: PropTypes.arrayOf(
          PropTypes.shape({
            message: PropTypes.string
          })
        ),
        extraChatsCount: PropTypes.number,
        author: PropTypes.string,
        dtime: PropTypes.string,
      })
    ),
    loading: PropTypes.bool,
    errors: PropTypes.bool,
  }),
  bot:PropTypes.shape({
    data: PropTypes.object,
    error: PropTypes.bool,
    fetching: PropTypes.bool
  }),
  operators: PropTypes.shape({
    data: PropTypes.object,
    error: PropTypes.bool,
    fetching: PropTypes.bool
  }),
  dispatch: PropTypes.func.isRequired,
  dispatchAddMessage: PropTypes.func.isRequired,
};
export default MessagesList;
