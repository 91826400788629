import { put, select } from 'redux-saga/effects';
import { formatAMPM, convert24Hours } from '../utils/formatDate';
import { 
  messageReceived,
  diseableLiveChatHours,
  disableUserInput
}  from '../actions';

const getBotState = (state) => state.bot;
const weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
export function* matchOffsetTime(action) {
  try {
    const state = yield select(getBotState);
    const openHours = state.data.openHours;
    const cDate = new Date();
    const finalDate = formatAMPM(cDate);
    if (openHours !== null){
      const offsetDateObj = getOffsetDateTime(openHours.timezone_offset);
      const day = weekdays[offsetDateObj.getDay()];
      //console.log(day+'_hours_chk');
      //console.log(openHours[day+'_hours_chk']);
      if (openHours[day+'_hours_chk'] === 'on' ) {
        //match hours here
        const offsetTime = formatAMPM(offsetDateObj, false);
        const isOpen = isOpenTime(offsetTime, day, openHours);
        if (isOpen) {
          yield put(diseableLiveChatHours());
          let markup = 'Sounds good. How would you like to connect <button type="button" data-type="qrb" class"quick_reply">Phone</button> <button type="button" data-type="qrb" class"quick_reply">Request a Callback</button><button type="button" data-type="qrb" class"quick_reply">Live Chat</button>';
          yield put(messageReceived(markup, 'bot',0,finalDate,false));
          yield put(disableUserInput());
        } else {
          yield put(diseableLiveChatHours());
          let markup = 'Sounds good. How would you like to connect? <button type="button" data-type="qrb" class"quick_reply">Phone</button> <button type="button" data-type="qrb" class"quick_reply">Request a Callback</button>';
          yield put(messageReceived(markup, 'bot',0,finalDate,false));
          yield put(disableUserInput());
        }
      } else {
        yield put(diseableLiveChatHours());
        let markup = 'Sounds good. How would you like to connect? <button type="button" data-type="qrb" class"quick_reply">Phone</button> <button data-type="qrb" type="button" class"quick_reply">Request a Callback</button>';
        yield put(messageReceived(markup, 'bot',0,finalDate,false));
        yield put(disableUserInput());
      }
    } else {
      yield put(diseableLiveChatHours());
      let markup = 'Sounds good. How would you like to connect? <button type="button" data-type="qrb" class"quick_reply">Phone</button> <button type="button" data-type="qrb" class"quick_reply">Request a Callback</button>';
      yield put(messageReceived(markup, 'bot',0,finalDate,false));
      yield put(disableUserInput());
    }
  }
  catch (error) {
    //console.log(error);
    //yield put(callFailure("BOT_CALL_FAILURE", error));
  }
}

const getOffsetDateTime = (offset) => {
  const targetTime = new Date();
  const timeZone = parseFloat(offset);
  const tzdiff =  targetTime.getTimezoneOffset() - timeZone  ;
  const offsetTime = new Date(targetTime.getTime() + tzdiff *60*1000);
  return offsetTime;
}

const isOpenTime = (offsetTime, day, openHours) => {
  const fTime = parseFloat(convert24Hours(offsetTime));
  const onTime = parseFloat(convert24Hours(openHours[day+'_start_hours']));
  const offTime = parseFloat(convert24Hours(openHours[day+'_end_hours']));
  if (fTime >=onTime && fTime <  offTime) return true;
  return false;
}
/*
const stdTimezoneOffset = function (clientDate) {
  const jan = new Date(clientDate.getFullYear(), 0, 1);
  const jul = new Date(clientDate.getFullYear(), 6, 1);
  return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
}

const isDstObserved = function (clientDate) {
  return clientDate.getTimezoneOffset() < stdTimezoneOffset(clientDate);
}

const getDSTdiff = (dstOffset, stdOffset) => {
  const dstOffsetInt = (dstOffset/60)*-1;
  const stdOffsetInt = stdOffset/60;
  const diff = stdOffsetInt + dstOffsetInt;
  return diff;
}
*/