const initialState = {
  fetching: false,
  chats: [],
  extraChats : [],
  loading: false,
  extraChatsCount:0,
  dtime:null,
  feedBack:true,
  inputDisabled: false,
  inputEnabled: true,
  dtPicker: false,
  locations: true,
  selectList: [],
  clicked: false,
  showCXText: false,
  disbaleCXtext: false,
  showAppointmentForm: false,
  intentAction: '',
  intentMessage: '',
  showIntentForm: false,
  showChatAddMessage: false,
  showFeedbackText: false,
  waitingForForm: false,
  showFormError: false,
  streaming: false,
  showAncButton: false,
};
const message = (state = initialState, action) => {
  let oldChats = state.chats
  switch (action.type) {
    case 'ADD_MESSAGE':
      let showCXText = false;
      return { ...state,
        chats: oldChats.concat([{
          message: action.message,
          author: action.author,
          session: action.session,
          id: action.id,
          lmore: '',
          showAncButton: false
        }]),
        loading: false,
        errors: action.errors,
        feedBack: state.feedBack,
        inputEnabled: true,
        inputDisabled: false,
        streaming: false,
        showCXText,
        showAncButton: false,
      };
    case 'ADD_EXTRA_MESSAGE':
      let lastIndex = oldChats.length-1
      let extraChats = [];
      if(undefined !== state.chats[lastIndex].extraChats)
        extraChats = state.chats[lastIndex].extraChats
      if(action.extra_message !=="")
        state.chats[lastIndex].extraChats = extraChats.concat([{
          message:action.extra_message,
        }])
        state.chats[lastIndex].extraChatsCount=action.extraChatsCount
        state.chats[lastIndex].dtime=action.dtime
        state.chats[lastIndex].lmore = '';
        state.chats[lastIndex].showAncButton = action.showAncButton;
      return { ...state,
        chats: oldChats,
        lmore: action.lmore,
        showAncButton: action.showAncButton,
        loading: false,
        errors:action.errors,
        showCXText: false,
      };
    case "CHAT_VALIDATION_ERROR":
      return { ...state,
        loading: false,
        errors:action.errors,
      };
    case 'MESSAGE_RECEIVED':
      return { ...state,
        chats: oldChats.concat([{
          message:action.message,
          author: action.author,
          session: action.session,
          extraChatsCount:action.extraChatsCount,
          id:action.id,
          dtime:action.dtime,
          clicked: false,
          lmore: action.lmore,
          showAncButton: action.showAncButton,
        }]),
        loading: false,
        errors:action.errors,
        feedBack: action.feedBack,
        showCXText: false,
        showAncButton: action.showAncButton,
      };
      
    case  'MESSAGE_WILL_BE_RECEIVED':
      showCXText = false;
      if (state.chats.length > 0) {
        const count = state.chats.length -1;
        const last_message = state.chats[count]
        //if (last_message.author == 'Me') showCXText = true
      }
      return { ...state,
        loading: true,
        showCXText,
      };
    
    case 'DISABLEUSERINPUT':
      return { ...state,
        inputDisabled: true,
        inputEnabled: false,
      };
    
    case 'ENABLEUSERINPUT':
      return { ...state,
        inputEnabled: true,
        inputDisabled: false,
      };
    case 'DISABLECAL':
      return { ...state,
        dtPicker: false,
      };
    
    case 'ENABLECAL':
      return { ...state,
        dtPicker: true,
      };

    case 'DISABLEMULSEL':
      return { ...state,
        selectList: [],
      };
    
    case 'SHOWAPPOINTMENTFORM':
      return { ...state,
        showAppointmentForm: true,
        intentAction: action.intentAction,
        intentMessage: action.intentMessage,
        loading: false,
        showCXText: false,
      };
    

    case 'BUTTONCLICKED':
      let clastIndex = oldChats.length-1;
      state.chats[clastIndex].clicked = true;
      return  { ...state,
      };
      
    case 'ENABLEMULSEL':
      return { ...state,
        selectList: [...state.selectList, action.mulItem],
      };
    
    case 'SHOWSEARCHWEBSITE':
      let defVal = true;
      if (state.disbaleCXtext)defVal = false;
      if (state.waitingForForm) defVal = false;
      return { ...state,
        showCXText: defVal,
      };
  
    case 'HIDESEARCHWEBSITE':
      return { ...state,
        loading: false,
        showCXText: false,
      };
    case 'DISABLESEARCHWEBSITE':
      return { ...state,
        disbaleCXtext: true,
      };
    case 'ENABLESEARCHWEBSITE':
      return { ...state,
        disbaleCXtext: false,
      };  
    
    case 'SHOWINTENTFORM':
      return { ...state,
        showIntentForm: true,
        showFeedbackText: false,
        showFormError: false,
        loading: false,
        showCXText: false,
        waitingForForm: false
      };
    
    case 'SHOWCHATADDMESSAGE':
      return { ...state,
        showChatAddMessage: true,
      };
    case 'CHANGEQUICKREPLYMESSAGE':
      return { ...state,
        intentAction: action.intentAction,
        intentMessage: action.intentMessage
      };
    
    case 'CONTINUEACTION':
      return { ...state,
        showAppointmentForm: false,
        intentAction: '',
        intentMessage: '',
        showIntentForm: false,
        showFeedbackText:  false,
        showFormError: false,
        showChatAddMessage: false,
        loading: false,
        showCXText:false,
      };
    case 'CONTINUEACTIONNOLOADING':
      return { ...state,
        showAppointmentForm: false,
        intentAction: '',
        intentMessage: '',
        showIntentForm: false,
        showChatAddMessage: false,
        loading: false,
        showCXText:false,
      };
    case 'SHOWFEEDBACKTEXT':
      return { ...state,
        showFeedbackText: true,
        showFormError: false,
      };
    case 'SHOWFORMERROR':
      return { ...state,
        showFormError: true,
      }
    case 'WAITINGFORFORM':
      return { ...state,
        waitingForForm: true,
        showFormError: true,
      }
    case 'RESTART_CHAT':
      const initChat = [];
      initChat.push(oldChats[0]);
      return { ...state,
        chats: initChat
      }
    
    case 'ADD_MESSAGE_STREAM':
      if (state.chats.length > 0) {
        const count = state.chats.length -1;
        //const last_message = state.chats[count];
        if (state.chats[count].author == 'bot') {
          state.chats[count].message= action.message;
          state.chats[count].lmore =  action.lmore;
        } else {
          state.chats.push({
            message:action.message,
            author: action.author,
            session: action.session,
            extraChatsCount:0,
            id:action.id,
            dtime:action.dtime,
            clicked: false,
            lmore: action.lmore,
          });
        }
      }
      return { ...state,
        loading: false,
        errors:action.errors,
        streaming: true,
        feedBack: action.feedBack,
        showCXText: false,
      };


    default:
      return state
  }
}

export default message;