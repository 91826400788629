import { connect } from 'react-redux';
import ErrorMessageComponent from '../components/ErrorMessage';

const mapDispatchToProps = dispatch => ({
  dispatch: (message, author) => {
    /* if(message!=="")
      dispatch(addMessage(message, author))
    else{
      let errors = chatValidationErrors({message:message})
      if (!chatIsValid(errors))
        dispatch(messageError(errors))
    } */
  },
});


export const ErrorMessage = connect(state => ({
  messages: state.messages
}), mapDispatchToProps)(ErrorMessageComponent)