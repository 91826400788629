import * as types from '../constants/ActionTypes'

let nextMessageId = 1
let nextUserId = 0

export const clickedMessage =  () => ({
  type: types.BUTTONCLICKED,
});

export const addMessage = (message, author,session,feedback) => ({
  type: types.ADD_MESSAGE,
  id: nextMessageId++,
  message,
  author,
  session,
  feedback,
})

export const addUser = name => ({
  type: types.ADD_USER,
  id: nextUserId++,
  name
})

export const messageWillbeReceived = (feedBack) => ({
  feedBack,
  type:types.MESSAGE_WILL_BE_RECEIVED,
  id: nextMessageId++
});

export const disableUserInput = () => ({
  type: types.DISABLEUSERINPUT
});

export const enableInput = () => ({
  type: types.ENABLEUSERINPUT
});


export const disableCal = () => ({
  type: types.DISABLECAL
});

export const enableCal = () => ({
  type: types.ENABLECAL
});

export const disableLocs = () => ({
  type: types.DISABLELOCS
});

export const enableLocs = () => ({
  type: types.ENABLELOCS
});

export const enableLiveChatHours = () => ({
  type: types.ENABLELCHOURS
});

export const diseableLiveChatHours = () => ({
  type: types.DISABLELCHOURS
});

export const addSelectList = (item) => ({
  type: types.ENABLEMULSEL,
  mulItem: item,
});

export const clearSelectList = () => ({
  type: types.DISABLEMULSEL
});

export const messageReceived = (message,author,extraChatsCount,dtime,feedBack,lmore = '', showAncButton) => ({
  type: types.MESSAGE_RECEIVED,
  id: nextMessageId++,
  message,
  extraChatsCount:extraChatsCount,
  dtime:dtime,
  feedBack,
  author,
  lmore,
  showAncButton,
})

export const additionalMessageRecieved = (message,extraChatsCount,dtime,feedBack, lmore = '', showAncButton) =>({
  type:"ADD_EXTRA_MESSAGE",
  extra_message:message,
  dtime:dtime,
  extraChatsCount:extraChatsCount,
  feedBack,
  lmore,
  showAncButton
})

export function messageError(errors) {  
  return {
    type: "CHAT_VALIDATION_ERROR",
    errors
  }
}
export const  showSearchWebsiteMessage = () => ({
  type: types.SHOWSEARCHWEBSITE,
});

export const  hideSearchWebsiteMessage = () => ({
  type: types.HIDESEARCHWEBSITE,
});

export const disableSearchWebsiteMessage = () => ({
  type: types.DISABLESEARCHWEBSITE,
});

export const enableSearchWebsiteMessage = () => ({
  type: types.ENABLESEARCHWEBSITE,
});

export const enableAppointmentForm = ( action, message) => ({
  type: types.SHOWAPPOINTMENTFORM,
  intentAction: action,
  intentMessage: message
});

export const showIntentForm = () => ({
  type: types.SHOWINTENTFORM,
});

export const showChatAddMessage = () => ({
  type: types.SHOWCHATADDMESSAGE,
});

export const changeQuickReplyMessage = ( action, message) => ({
  type: types.CHANGEQUICKREPLYMESSAGE,
  intentAction: action,
  intentMessage: message
});

export const continueAction = () => ({
  type: 'CONTINUEACTION',
});

export const continueActionNoLoading = () => ({
  type: 'CONTINUEACTIONNOLOADING',
});

export const addFormData = (formData) => ({
  type: 'PARAMETERS_POST_REQUEST',
  formData
});

export const showLoading = () => ({
  type: 'SHOWFEEDBACKTEXT'
});

export const waitForForm = () => ({
  type: 'WAITINGFORFORM'
});

export const showFormError = () => ({
  type: 'SHOWFORMERROR'
});

export const restart_chat = () =>({
  type: 'RESTART_CHAT'
});

export const clearMessage = () => ({
  type: 'REMOVE_MESSAGES'
});

export const streamMessageReceived = (message,author,dtime,feedBack,lmore) => ({
  type: 'ADD_MESSAGE_STREAM',
  id: nextMessageId++,
  message,
  dtime:dtime,
  feedBack,
  author,
  lmore
})
