import React from "react";
import PropTypes from "prop-types";
import {parseMessage, createMarkup, createAnchor} from "../utils/parser";

const ExtraMessage = (props) => {
  let message = props.extraMessage.message
  let finalDate = props.dtime;
  let customStyles =props.style;
  if(undefined !== message ){
    const showAncButton = props.showAncButton;
    let messages = {text:'',html:'',orignMessage:''};
    if (!showAncButton)  messages = parseMessage(message, showAncButton);
    let ShowDate = ``;
    let lmore='', lmoreClass = 'date-more';
    if(props.currentIndex === (props.extraChatsCount-1)){
        ShowDate = `${finalDate}`;
        lmore = props.lmore;
        if (lmore != '')
          lmoreClass = 'learn-more-date';
    }
    if (showAncButton) {
      messages.html = createAnchor(props.extraMessage.message);
      //messages.text = messages.orignMessage;
      //replace anchor text from welcome message
      let aStrt =  props.extraMessage.message.indexOf('<a');
      if (aStrt === -1) aStrt = props.extraMessage.message.length;
      const text = props.extraMessage.message.substr(0, aStrt);
      messages.orignMessage =text;
      messages.text =text;
    }
    if(messages.html === "") {
      if(props.bot.cssTheme === 'option2') {
        //console.log("date-div 1");
        //console.log(customStyles);

        return(
          <div class="date-div">
            <div className="chat-text" style={customStyles}>
              <p dangerouslySetInnerHTML={createMarkup(messages.orignMessage,"")}/>
            </div>
            <div className={lmoreClass}>
              {
              (messages.showCancelLink) ?
              <span><a onClick={props.handleClick}  href="javascript:;">Never Mind</a>&nbsp;</span>
              :
              ""
              }
              <span dangerouslySetInnerHTML={createMarkup(lmore,'')}/>
              {ShowDate}
            </div>
          </div>
        )
      } else {
        //console.log("date-div 2");
        return(
          <div className="chat-text" style={customStyles}>
              <p dangerouslySetInnerHTML={createMarkup(messages.orignMessage,"")}/><b>{ShowDate}</b>
          </div>
        )
      } 
    } else {
      if(props.bot.cssTheme === 'option2') {
        let btnMrkup = ``;
        if (messages.html.includes('type="qrb"')) {
          btnMrkup = createMarkup(messages.html,customStyles);// CTA style
        } else {
          const html = messages.html.replace(/type="button"/g, 'type="button" style="font-weight:100;"')
          btnMrkup = createMarkup(html,"");// without custom styling
        }
        if (messages.text.includes('<a')) { 
          const startIndex = messages.text.indexOf('<a');
          const lastIndex = messages.text.lastIndexOf('</a>');
          const searchVal = messages.text.substring(startIndex + 9, lastIndex + 4);
          messages.text = messages.text.replace(searchVal, '');
        }
        //console.log('props.extraMessage.message props.extraMessage.message props.extraMessage.message',messages.text);
    
        return(
          <div class="date-div">
            <div className="chat-text test" style={customStyles}>
            <p dangerouslySetInnerHTML={createMarkup(messages.text,customStyles)}/> 
            </div>
            <br/>
            <div className={lmoreClass}>
            {
              (messages.showCancelLink) ?
              <span><a onClick={props.handleClick}  href="javascript:;">Never Mind</a>&nbsp;</span>
              :
              ""
              }
              <span dangerouslySetInnerHTML={createMarkup(lmore,'')}/>
                {ShowDate}
              </div>
            <div className="button-include"> 
              <div onClick={props.handleClick} className="quick_reply" dangerouslySetInnerHTML={btnMrkup} />
            </div>
          </div>
        )
      } else {
        return( 
          <div className="button-include"> 
            <div className="chat-text">  
            <p dangerouslySetInnerHTML={createMarkup(messages.text,customStyles)}/>  <b>{ShowDate}</b>
            </div>
            <div onClick={props.handleClick} className="quick_reply" dangerouslySetInnerHTML={createMarkup(messages.html)} />
          </div>
        )
      }        
    }
  } else return "";
}

ExtraMessage.propTypes = {
  message: PropTypes.string,
  author: PropTypes.string,
  props:PropTypes.object
}

export default ExtraMessage

//15 hours to add